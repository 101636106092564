/* Add this to your CSS file */
.card-blog-grid {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This ensures the footer aligns at the bottom */
    height: 100%; /* Set a fixed height or make it consistent for all cards */
  }
  
  .thumbnail img {
    width: 100%; /* Make the image width responsive */
    height: 300px; /* Fix the image height */
    object-fit: cover; /* This will ensure the image covers the area without distorting */
  }
  