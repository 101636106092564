/* Styles for the wishlist button */
.axil-btn.wishlist-btn {
  display: flex;         /* Use Flexbox */
  align-items: center;   /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  border: none;
  cursor: pointer;
  background-color: transparent; /* Assuming you don't want a bg color */
}

.small-thumb-img {
  cursor: pointer;
  margin-right: 10px;
  /*oranyspacingyouwantbetweenimages*/transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
}

.size-buttons-size-button.selected {
  border: 2px solid black;
}
.text-in-stock {
  color: green;
}

.text-out-of-stock {
  color: red;
}

.thumbnail-container {
  overflow: hidden;
  position: relative;
  cursor: crosshair;
}

.thumbnail-image {
  transition: transform 0.25s ease;
  width: 100%;
  height: auto;
  transform-origin: center center;
}

.text-in-stock {
  color: #04ce00; /* green */
}

.text-out-of-stock {
  color: red;
}
.size-buttons-size-button-default {
  background-color: #fff;
  border: 1px solid #bfc0c6;
  border-radius: 50px;
  padding: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  color: #282c3f;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  margin:10px;
}

.size-buttons-size-button {
  position: relative
}

.size-buttons-size-button-selected {
  border: 1px solid #ff3f6c;
  background-color: #fff;
  color: #ff3f6c!important
}

/* .size-buttons-size-button-disabled {
  color: #d5d6d9;
  border: 1px solid #d5d6d9;
  cursor: default;
  font-weight: 700;
  outline: none;
  overflow: hidden
} */

.size-buttons-size-strike-hide {
  width: 0;
  height: 0
}

.size-buttons-size-strike-show {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #d5d6d9;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.size-buttons-big-size {
  min-height: 48px;
  min-width: 60px;
  border-radius: 50px;
  height: auto;
  width: auto;
  padding: 0 10px;
  font-weight: 700
}



.similar-products-section {
  margin: 20px 0;
  padding: 20px;
  
}

.similar-products-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-item {
  flex: 0 0 calc(25% - 20px);
  margin: 10px;
  /* border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease; */
}

.product-item:hover {
 
  transform: scale(1);
}
.size-buttons-size-button.selected {
  border-color: #000; /* black border */
}
.size-buttons-size-button:focus {
outline: none;
}