@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.no-results-container {
    text-align: center;
    animation: fadeInUp 1s forwards;
}

.no-results-icon {
    font-size: 50px;
    color: #888; /* You can adjust this color */
    display: block;
    margin: 20px auto;
}

.no-results-text {
    font-size: 18px;
    color: #333; /* You can adjust this color */
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideInFromRight {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
}

.no-results-container {
    text-align: center;
    max-width: 600px;
    margin: 40px auto;
    padding: 40px;
    border: 1px solid #f04f23ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s forwards;
}

.no-results-icon {
    font-size: 80px;
    color: #f04f23ff;
    display: block;
    margin: 20px auto;
    animation: slideInFromRight 1s 0.5s forwards;
}

.no-results-text {
    font-size: 24px;
    font-weight: bold;
    color: black;
    margin-top: 20px;
    animation: fadeIn 1s 1s forwards;
}

.no-results-description {
    color: #888;
    margin-top: 10px;
    animation: fadeIn 1s 1.5s forwards;
}

.cta-buttons {
    margin-top: 20px;
}

.cta-button {
    display: inline-block;
    padding: 10px 20px;
    margin: 5px;
    background-color: #f04f23ff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
    
    &:hover {
        background-color: #d93d13ff;
    }
}

/* Overall page styling */
body, html {
    font-family: 'Arial', sans-serif;  /* A simple default font; replace with your site's font */
    background-color: #f6f6f6; /* A soft gray background for some contrast */
}

.full {
    max-width: 1200px; /* You can adjust this as per your design needs */
    margin: 0 auto; /* Centers the content horizontally */
    padding: 20px; /* Adds some spacing on the sides for smaller screens */
}


h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
}

/* Styling for the no results scenario */
.no-results-container {
    text-align: center;
    background-color: white; /* A white box that stands out against the gray background */
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* A subtle shadow */
    
}

.no-results-icon {
    font-size: 80px;
    color: #f04f23ff; /* Using the color you provided */
    margin-bottom: 20px;
}

.no-results-text {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
}

.no-results-description {
    font-size: 16px;
    color: #777;
    margin-bottom: 25px;
}

.cta-buttons a.cta-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f04f23ff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.2s;
}

.cta-buttons a.cta-button:hover {
    background-color: #d93d13ff; /* A slightly darker shade for hover */
}

/* Styling for the results section */
.cont {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adaptable grid layout */
    gap: 20px;
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width:720px) {
    .cont {
        display: grid;
        grid-template-columns: max-content;
       
    }
}
