.star-rating-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px; /* Adjust the gap between stars as needed */
  }
  
  .active-star, .inactive-star {
    cursor: pointer;
    font-size: 20px;
  }
  
  .active-star {
    color: gold;
  }
  
  .inactive-star {
    color: grey;
  }
  