.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000; /* Increase z-index to a much higher value */
  }
  
  .modal-content {
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    z-index: 10001;

  }
  
  .close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    z-index: 100;

  }
  
  .close-btn:hover,
  .close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  