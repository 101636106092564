.size-button{
  color: black;
}

.size-button:hover{
  color: #f04f23 !important;
}

.selected {
    color: #f04f23;
}


.size-button.selected {
  background-color: #f5f5f5; /* Adjust to the desired background color for selected size */
  border: 2px solid black; /* Adjust the border color as needed */
  box-sizing: border-box; 
}

.color-extra-blue {
    background-color: blue;
    /* additional styles */
  }

  .hidden {
    visibility: hidden;
  }
  
  
  .color-extra-black {
    background-color: black;
    /* additional styles */
  }
  
  /* ... and so on for each color */
  .color-item.selected {
    border: 2px solid black; /* or any other style to show it's selected */
  }