/* ResetPassword.css */

.reset-password-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 10px 50px 30px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .reset-password-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #555;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
    color: #333;
  }
  
  .btn-primary {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .alert {
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 3px;
  }
  
  .alert-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
  }
  
  .alert-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
  }
  