.useAddressButton {
    padding: 10px 20px;
    background-color: #F04F23; /* Blue color */
    color: #ffffff; /* White color text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    margin-top: 10px;
    transition: background-color 0.3s, transform 0.3s;
    width: 200px;
}

.useAddressButton:hover {
    background-color: #ff3700; /* Darker blue on hover */
    transform: scale(1.05); /* Slightly enlarges the button on hover */
}

.useAddressButton:focus {
    outline: none; /* Removes the default browser focus style */
}

.disabled-field {
    background-color: #f3f3f3;
    pointer-events: none; /* Make it non-clickable */
  }
  