.card-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .card {
    border: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
    width: 300px;
  }

  .product-style-one {
    padding: 0px !important;
    
}