@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
/* styles.css */
.in-stock {
    color: green;
  }
  
  .out-of-stock {
    color: red;
  }
  
.no-wishlist-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    border: 2px dashed #ccc;
    border-radius: 10px;
    animation: fadeInUp 1s forwards;
}

.no-wishlist-icon {
    font-size: 60px;
    color: #f04f23ff;
    margin-bottom: 20px;
    animation: pulse 2s infinite;
}

.no-wishlist-text {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}
.quick-links {
    margin-top: 40px;
    text-align: center;
}

.quick-links-heading {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #f04f23ff;
    border-bottom: 2px solid #f04f23ff;
    display: inline-block;
    padding-bottom: 5px;
}

.category-link {
    margin: 0 15px;
    font-size: 18px;
    color: black; 
    text-decoration: none;
    position: relative;
    transition: all 0.3s;

    &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        background-color: #f04f23ff;
        bottom: -5px;
        left: 50%;
        transition: all 0.3s;
    }

    &:hover {
        color: #f04f23ff;

        &:after {
            width: 100%;
            left: 0;
        }
    }
}

.home-link {
    display: inline-block;
    margin-bottom: 20px;
    padding: 5px 15px;
    background-color: #04ce00; 
    color: white;
    text-decoration: none;
    border-radius: 5px;

    &:hover {
        background-color: #02a400; 
        color: white;
    }
}


.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    padding: 40px; /* Adequate spacing around the modal */
}

.modal-content {
    background-color: #fff; /* White background for contrast */
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 400px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border: 2px solid #f04f23; /* Border color as per theme */
}

/* Heading style */
.modal-content h2 {
    color: #000; /* Black heading for contrast */
    margin-bottom: 20px; /* Spacing below heading */
}

/* Size buttons */
.size-buttons button {
    background-color: #fff; /* White background for buttons */
    color: #f04f23; /* Theme color for button text */
    border: 2px solid #f04f23; /* Border color as per theme */
    margin: 5px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
}

/* Hover effect for buttons */
.size-buttons button:hover {
    background-color: #f04f23; /* Theme color background on hover */
    color: #fff; /* White text on hover */
}

/* Active button style */
/* Active button style */
.size-buttons button.active {
    background-color: #f04f23; /* Theme color background for active button */
    color: #fff; /* White text for active button */
    border: 2px solid #000; /* Black border for active button */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Shadow for lifted appearance */
}


/* Add to Cart and Close button style */
.modal-content .btn-primary, .modal-content .btn-secondary {
    background-color: #f04f23; /* Theme color for buttons */
    color: #fff; /* White text for buttons */
    margin-top: 20px;
    padding: 10px 20px;
}

/* Close button styling */
.modal-content .btn-secondary {
    background-color: #000; /* Black background for close button */
   
}

/* Hover effect for Add to Cart and Close buttons */
.modal-content .btn-primary:hover, .modal-content .btn-secondary:hover {
    background-color: #fff; /* White background on hover */
    color: #f04f23; /* Theme color text on hover */
    border: 2px solid #f04f23; /* Border to maintain button size */
}
