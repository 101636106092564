.product-item2{
  border: none !important;
  background-color: none !important;
}
.product-carousel
{
  margin: 30px;
}
.custom-arrow {
  font-size: 20px; /* Adjust the size */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev {
  left: -25px; /* Adjust the position */
}

.slick-next {
  right: -25px; /* Adjust the position */
}


.adjustment{
  margin: 15px !important; /* Adjust the right padding to create space between cards */
  box-sizing: border-box;
}
@media (max-width:480px) {
  .adjustment{
    margin: 8px !important;
  }
}
.slick-prev:before, .slick-next:before{
  color: black !important;
}